import React from 'react';
import * as styles from './index.styles';

export type Props = {
  label: React.ReactNode;
  error?: boolean;
  id: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id'>;

export const Check: React.FC<Props> = (props: Props) => {
  const { label, error, ...defaultInputProps } = props;

  return (
    <div css={styles.container({ error: !!error })}>
      <input {...defaultInputProps} type="checkbox" css={styles.defaultInput} />
      <label htmlFor={props.id} css={styles.label}>
        <div css={styles.checkboxImageContainer} />
        {label}
      </label>
    </div>
  );
};
