import React from 'react';
import { useRecoilValue } from 'recoil';
import { errorMessagesState } from '~/stores/errorMessages';
import * as styles from './index.styles';

type BaseProps = { messages: string[] };

const Base: React.FC<BaseProps> = (props) => {
  return props.messages.length === 0 ? null : (
    <ul css={styles.container}>
      {props.messages.map((message, idx) => (
        <li key={idx}>{message}</li>
      ))}
    </ul>
  );
};

export const ErrorMessages = {
  Base,
  WithStore: () => {
    const messages = useRecoilValue(errorMessagesState);
    return <Base messages={messages} />;
  },
};
