import { CompanyIndustryOption } from '~/models/types';

const industries: CompanyIndustryOption[] = [
  {
    masterCompanyIndustryId: 0,
    label: '飲食／フード',
    requiredStoreNumber: true,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 1,
    label: '小売／販売',
    requiredStoreNumber: true,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 2,
    label: '接客／サービス',
    requiredStoreNumber: true,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 3,
    label: 'イベント／エンタメ',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 4,
    label: '清掃・警備',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 5,
    label: '事務',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 6,
    label: '物流／配送',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 7,
    label: '軽作業',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 8,
    label: 'コールセンター',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 9,
    label: '医療／介護／福祉／保育',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 10,
    label: '専門職／その他',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 11,
    label: '派遣',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 12,
    label: 'メーカー',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 13,
    label: '農業・漁業',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 14,
    label: '卸売',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 15,
    label: '建設',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 16,
    label: '港湾',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 17,
    label: '製造',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
];

const requirePattern: CompanyIndustryOption[] = [
  {
    masterCompanyIndustryId: 0,
    label: 'store:true,perDay:true',
    requiredStoreNumber: true,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 1,
    label: 'store:true,perDay:false',
    requiredStoreNumber: true,
    requiredPartTimeMaxCountPerDay: false,
  },
  {
    masterCompanyIndustryId: 2,
    label: 'store:false,perDay:true',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: true,
  },
  {
    masterCompanyIndustryId: 3,
    label: 'store:false,perDay:false',
    requiredStoreNumber: false,
    requiredPartTimeMaxCountPerDay: false,
  },
];

export const companyIndustryOption = (pattern: number): CompanyIndustryOption =>
  industries[pattern % industries.length];

companyIndustryOption.all = industries;
companyIndustryOption.requirePattern = requirePattern;
