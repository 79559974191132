import axiosClient from '@aspida/axios';
import api from '~/apis/AutogenTimeeRegisterApiClient/$api';
import axios, { AxiosInstance } from 'axios';
import { stringify } from 'querystring';
import { apiConfig } from '~/constants/apiConfig';
import { publicEnv } from '~/constants/publicEnv';

const configuredAxiosClient = (): AxiosInstance => {
  const client = axios.create({
    baseURL: publicEnv.apiBaseUrl,
    withCredentials: true,
    // https://qiita.com/m_mitsuhide/items/7b638cf93dd086f27b8f
    paramsSerializer: (params) => stringify(params),
    headers: {
      [apiConfig.timeeRegisterCustomHeader]: apiConfig.timeeRegisterCustomHeader,
    },
  });

  return client;
};

export const aspidaClient = api(axiosClient(configuredAxiosClient()));
