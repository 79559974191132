import { CompanyOccupationOption } from '~/models/types';

const samplePatterns: string[] = [
  'ホール',
  'キッチン',
  '洗い場',
  'レジ・品出し・商品整理',
  '接客・販売',
  'ポスティング・ビラ配り',
  'コンビニ業務',
  'ホテル業務',
  '受付・案内',
  '事務・データ入力',
  'コールセンター・テレアポ',
  '教育・指導',
  '引越し・運搬',
  '会場設営',
  '梱包・シール貼り',
  '検品・仕分け',
  '清掃',
  '作業補助',
  'フードデリバリー',
  '配達・配送',
  '送迎',
  '保育',
  'その他',
];

const all: CompanyOccupationOption[] = samplePatterns.map((value, idx) => ({
  masterCompanyOccupationId: idx,
  label: value,
}));

export const companyOccupationOption = (pattern: number): CompanyOccupationOption =>
  all[pattern % all.length];

companyOccupationOption.all = all;
