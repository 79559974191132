import { css, keyframes } from '@emotion/react';
import { textStyle } from '~/styles/textStyle';
import { colors } from '~/styles/colors';
import { layout } from '~/styles/layout';
import { alignCss } from '~/styles/alignCss';

const formHeightPx = '44px';

export const container = (options: { width?: number }) => css`
  position: relative;
  ${options.width ? `width: ${options.width}px` : '100%'};
`;

export const eyeContainer = {
  container: css`
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0 12px;
    top: 0;
    right: 0;
    height: ${formHeightPx};
  `,
  eye: css`
    width: 20px;
  `,
};

export const input = (options: { readOnly?: boolean; error?: boolean }) => css`
  ${textStyle('s', 'regular')}

  width: 100%;
  box-sizing: border-box;
  height: ${formHeightPx};
  line-height: ${formHeightPx};
  border: 2px solid ${colors.gray5};
  border-radius: 8px;
  padding: 0 ${layout.baseSize.margin}px;
  border-color: ${options.error ? 'red' : 'default'};
  cursor: ${options.readOnly ? 'not-allowed' : 'default'};

  ${options.readOnly
    ? `
      background-color: ${colors.gray6};
      `
    : ''}

  &::placeholder {
    color: ${colors.gray4};
  }

  &:focus-visible {
    outline-color: ${options.error ? 'red' : 'default'};
  }
`;

export const errorMessage = css`
  ${textStyle('xs', 'bold')}
  ${alignCss({ mt: 0.5 })}

  color: ${colors.alert};
`;

export const description = css`
  ${textStyle('xs', 'regular')}
`;

export const unit = css`
  position: absolute;
  text-align: right;
  right: 32px;
  line-height: ${formHeightPx};
  color: ${colors.gray4};
`;

const flashAnimation = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
`;

export const loadingCircle = css`
  animation: ${flashAnimation} 1s ease-in-out infinite;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: ${colors.accentPale};
  border-radius: 20px;
`;
