/* eslint-disable */
import { AspidaClient, BasicHeaders } from 'aspida'
import { Methods as Methods0 } from './register/api/v1/master/company/industries'
import { Methods as Methods1 } from './register/api/v1/master/company/issues'
import { Methods as Methods2 } from './register/api/v1/master/company/occupation_categories'
import { Methods as Methods3 } from './register/api/v1/master/company/occupations'
import { Methods as Methods4 } from './register/api/v1/master/company/part_time_max_count_per_days'
import { Methods as Methods5 } from './register/api/v1/self/web_entries'
import { Methods as Methods6 } from './register/api/v1/web_entries/_token@string'
import { Methods as Methods7 } from './register/api/v1/web_entries/_token@string/clients'
import { Methods as Methods8 } from './register/api/v1/web_entries/_token@string/companies'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/register/api/v1/master/company/industries'
  const PATH1 = '/register/api/v1/master/company/issues'
  const PATH2 = '/register/api/v1/master/company/occupation_categories'
  const PATH3 = '/register/api/v1/master/company/occupations'
  const PATH4 = '/register/api/v1/master/company/part_time_max_count_per_days'
  const PATH5 = '/register/api/v1/self/web_entries'
  const PATH6 = '/register/api/v1/web_entries'
  const PATH7 = '/clients'
  const PATH8 = '/companies'
  const GET = 'GET'
  const POST = 'POST'

  return {
    register: {
      api: {
        v1: {
          master: {
            company: {
              industries: {
                get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
                $get: (option?: { headers?: Methods0['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH0}`
              },
              issues: {
                get: (option?: { headers?: Methods1['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, PATH1, GET, option).json(),
                $get: (option?: { headers?: Methods1['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, PATH1, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH1}`
              },
              occupation_categories: {
                get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json(),
                $get: (option?: { headers?: Methods2['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods2['get']['resBody'], BasicHeaders, Methods2['get']['status']>(prefix, PATH2, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH2}`
              },
              occupations: {
                get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH3, GET, option).json(),
                $get: (option?: { headers?: Methods3['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH3}`
              },
              part_time_max_count_per_days: {
                get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH4, GET, option).json(),
                $get: (option?: { headers?: Methods4['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH4}`
              }
            }
          },
          self: {
            web_entries: {
              post: (option: { body: Methods5['post']['reqBody'], headers?: Methods5['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods5['post']['status']>(prefix, PATH5, POST, option).send(),
              $post: (option: { body: Methods5['post']['reqBody'], headers?: Methods5['post']['reqHeaders'], config?: T }) =>
                fetch<void, BasicHeaders, Methods5['post']['status']>(prefix, PATH5, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH5}`
            }
          },
          web_entries: {
            _token: (val0: string) => {
              const prefix0 = `${PATH6}/${val0}`

              return {
                clients: {
                  post: (option: { body: Methods7['post']['reqBody'], headers?: Methods7['post']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods7['post']['status']>(prefix, `${prefix0}${PATH7}`, POST, option).send(),
                  $post: (option: { body: Methods7['post']['reqBody'], headers?: Methods7['post']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods7['post']['status']>(prefix, `${prefix0}${PATH7}`, POST, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix0}${PATH7}`
                },
                companies: {
                  post: (option: { body: Methods8['post']['reqBody'], headers?: Methods8['post']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods8['post']['status']>(prefix, `${prefix0}${PATH8}`, POST, option).send(),
                  $post: (option: { body: Methods8['post']['reqBody'], headers?: Methods8['post']['reqHeaders'], config?: T }) =>
                    fetch<void, BasicHeaders, Methods8['post']['status']>(prefix, `${prefix0}${PATH8}`, POST, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix0}${PATH8}`
                },
                get: (option?: { headers?: Methods6['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods6['get']['resBody'], BasicHeaders, Methods6['get']['status']>(prefix, prefix0, GET, option).json(),
                $get: (option?: { headers?: Methods6['get']['reqHeaders'], config?: T }) =>
                  fetch<Methods6['get']['resBody'], BasicHeaders, Methods6['get']['status']>(prefix, prefix0, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix0}`
              }
            }
          }
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
