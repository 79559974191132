import { CompanyIssueOption } from '~/models/types';

const samplePatterns: string[] = [
  'スタッフの休みが多くシフトの穴が多い',
  'スタッフが辞めてしまうため人手不足(予定含む)',
  '採用をしているが集まらない',
  '繁忙期など一時的に人手がほしい',
  '派遣利用中だが、料金が高いと感じる',
  '人手不足に関して課題を感じていない',
  'その他',
];

const all: CompanyIssueOption[] = samplePatterns.map((value, idx) => ({
  masterCompanyIssueId: idx,
  label: value,
}));

export const companyIssueOption = (pattern: number): CompanyIssueOption =>
  all[pattern % all.length];

companyIssueOption.all = all;
