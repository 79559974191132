import { CurrentTokenStatus } from '~/models/types';

const defaultValue: CurrentTokenStatus = {
  email: 'example@example.com',
  status: 'emailEntered',
  flowType: 'self',
  idOneDigit: 1,
};
const emailEntered: CurrentTokenStatus = {
  ...defaultValue,
  status: 'emailEntered',
};
const companyCreated: CurrentTokenStatus = {
  ...defaultValue,
  status: 'companyCreated',
};
const companyReviewed: CurrentTokenStatus = {
  ...defaultValue,
  status: 'companyReviewed',
};
const done: CurrentTokenStatus = {
  ...defaultValue,
  status: 'done',
};
const failedCompanyDuplication: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedCompanyDuplication',
};
const failedCompanyNameIsTimee: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedCompanyNameIsTimee',
};
const failedCompanyReviewed: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedCompanyReviewed',
};
const failedCorporateNumberDuplication: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedCorporateNumberDuplication',
};
const failedTokenExpired: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedTokenExpired',
};

const statusList: CurrentTokenStatus['status'][] = [
  'emailEntered',
  'companyCreated',
  'companyReviewed',
  'done',
  'failedCompanyDuplication',
  'failedCompanyNameIsTimee',
  'failedCompanyReviewed',
  'failedCorporateNumberDuplication',
  'failedTokenExpired',
];

export const currentTokenStatus = (pattern: number): CurrentTokenStatus => ({
  email: `example+${pattern}@example.com`,
  status: statusList[statusList.length % pattern],
  flowType: 'self',
  idOneDigit: pattern % 10,
});

currentTokenStatus.default = defaultValue;
currentTokenStatus.emailEntered = emailEntered;
currentTokenStatus.companyCreated = companyCreated;
currentTokenStatus.companyReviewed = companyReviewed;
currentTokenStatus.done = done;
currentTokenStatus.failedCompanyDuplication = failedCompanyDuplication;
currentTokenStatus.failedCompanyNameIsTimee = failedCompanyNameIsTimee;
currentTokenStatus.failedCompanyReviewed = failedCompanyReviewed;
currentTokenStatus.failedCorporateNumberDuplication = failedCorporateNumberDuplication;
currentTokenStatus.failedTokenExpired = failedTokenExpired;
