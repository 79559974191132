import { css } from '@emotion/react';
import CheckIcon from '~/assets/icon/checkbox--checked.svg';
import NotCheckIcon from '~/assets/icon/checkbox--not-checked.svg';
import ErroredCheckIcon from '~/assets/icon/checkbox--errored.svg';
import { colors } from '~/styles/colors';

export const container = (options: { error: boolean }) => css`
  ${options.error
    ? css`
        & > input[type='checkbox'] + label > div {
          background-image: url(${ErroredCheckIcon}) !important;
        }
      `
    : ''}

  & > input[type='checkbox']:checked + label > div {
    background-image: url(${CheckIcon});
  }

  & > input[type='checkbox']:not(:checked) + label > div {
    background-image: url(${NotCheckIcon});
  }
`;

export const label = css`
  line-height: 24px;
  gap: 4px;
  cursor: pointer;
`;

export const checkboxImageContainer = css`
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 4px;
`;

// https://lifehackdev.com/ZakkiBlog/articles/detail/web41
const visuallyHidden = css`
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
`;

export const defaultInput = css`
  ${visuallyHidden}

  &:focus-visible ~ label > div {
    outline: 2px solid ${colors.accent};
  }
`;
