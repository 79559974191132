import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { textCss } from '~/styles/textCss';

export const container = css`
  ${textCss({ color: colors.alert, size: 's' })}

  list-style-type: disc;
  padding-left: 1.5em;
  margin-top: 1em; /* NOTE: 送信ボタンの下に置かれる前提で使われるので直接marginをもつ */
`;
