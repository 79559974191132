import axios from 'axios';
import { aspidaClient } from '~/apis/aspidaClient';
import { IRegisterApiClient } from './index.type';

export type { IRegisterApiClient } from './index.type';

export { registerMockApiClient } from './index.mock';

export const registerApiClient: IRegisterApiClient = {
  createNewTokenForSelfWorkflow: (params) => {
    return aspidaClient.register.api.v1.self.web_entries.$post({
      body: params,
    });
  },
  fetchCurrentTokenStatus: (params) => {
    return aspidaClient.register.api.v1.web_entries._token(params.token).$get();
  },
  registerCompanyAndAccountInfo: async (params) => {
    try {
      return await aspidaClient.register.api.v1.web_entries._token(params.token).companies.$post({
        body: {
          company: { ...params.company },
          metaOption: params.metaOption,
          account: params.account,
          salesforce: params.salesforce,
        },
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 409) return 'REFRESH_STATUS';
      throw err;
    }
  },
  registerClientInfo: async (params) => {
    try {
      return aspidaClient.register.api.v1.web_entries._token(params.token).clients.$post({
        body: params.client,
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 409) return 'REFRESH_STATUS';
      throw err;
    }
  },
  fetchMasterCompanyIssueOptions: async () => {
    return (await aspidaClient.register.api.v1.master.company.issues.$get()).results;
  },
  fetchMasterCompanyOccupationCategories: async () => {
    return (await aspidaClient.register.api.v1.master.company.occupation_categories.$get()).results;
  },
  fetchMasterCompanyPartTimeMaxCountPerDayOptions: async () => {
    return (await aspidaClient.register.api.v1.master.company.part_time_max_count_per_days.$get())
      .results;
  },
  fetchMasterCompanyIndustryOptions: async () => {
    return (await aspidaClient.register.api.v1.master.company.industries.$get()).results;
  },
};
