import { CompanyOccupationCategory, CompanyOccupationOption } from '~/models/types';
import { companyOccupationOption } from './companyOccupationOption';

const occupationsByLabelList = (labelList: string[]): CompanyOccupationOption[] => {
  return labelList
    .map((labelName) => companyOccupationOption.all.find((v) => v.label === labelName))
    .filter(
      (v: CompanyOccupationOption | undefined): v is CompanyOccupationOption => v !== undefined
    );
};

const sampleCategories: CompanyOccupationCategory[] = [
  {
    masterCompanyOccupationCategoryId: 1,
    label: '飲食',
    occupations: occupationsByLabelList(['ホール', 'キッチン', '洗い場']),
  },
  {
    masterCompanyOccupationCategoryId: 2,
    label: '接客・サービス',
    occupations: occupationsByLabelList([
      'レジ・品出し・商品整理',
      '接客・販売',
      'ポスティング・ビラ配り',
      'コンビニ業務',
      'ホテル業務',
      '受付・案内',
    ]),
  },

  {
    masterCompanyOccupationCategoryId: 3,
    label: 'オフィスワーク',
    occupations: occupationsByLabelList(['事務・データ入力', 'コールセンター・テレアポ']),
  },
  {
    masterCompanyOccupationCategoryId: 4,
    label: '教育・指導',
    occupations: occupationsByLabelList(['教育・指導']),
  },
  {
    masterCompanyOccupationCategoryId: 5,
    label: '軽作業',
    occupations: occupationsByLabelList([
      '引越し・運搬',
      '会場設営',
      '梱包・シール貼り',
      '検品・仕分け',
      '清掃',
      '作業補助',
    ]),
  },
  {
    masterCompanyOccupationCategoryId: 6,
    label: '運転・ドライバー',
    occupations: occupationsByLabelList(['フードデリバリー', '配達・配送', '送迎']),
  },
  {
    masterCompanyOccupationCategoryId: 7,
    label: '保育',
    occupations: occupationsByLabelList(['保育']),
  },
  {
    masterCompanyOccupationCategoryId: 8,
    label: 'その他',
    occupations: occupationsByLabelList(['その他']),
  },
];

export const companyOccupationCategory = (pattern: number): CompanyOccupationCategory =>
  sampleCategories[pattern % sampleCategories.length];

companyOccupationCategory.all = sampleCategories;
