import React, { useState } from 'react';
import * as styles from './index.styles';

// icons
import EyeInvisibles from '~/assets/icon/eye--invisble.svg';
import EyeVisibles from '~/assets/icon/eye--visible.svg';

export type AutoCompleteToken =
  | 'country'
  | 'postal-code'
  | 'address-line1' // 住所の1行目
  | 'address-line2' // 住所の2行目
  | 'organization'
  | 'name'
  | 'tel'
  | 'email'
  | 'new-password'
  // NOTE: MDN Web Docsでは「off」で無効とあるが、実際には未定義の文字列が渡されると無効になるため「nope」としている
  // 参照: https://developer.mozilla.org/ja/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
  | 'nope'; // Chromeの自動入力無効化

export type Props = {
  testId?: string;
  type: 'number' | 'text' | 'password' | 'tel' | 'email';
  error?: boolean;
  unit?: string;
  width?: number;
  loading?: boolean;
  datadogRumMask?: boolean;
  autoComplete?: AutoCompleteToken;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const MESSAGE = {
  ALT_DISPLAY_PASS: '表示',
  ALT_HIDE_PASS: '非表示',
} as const;

export const TESTID = {
  TOGGLE_PASS_BUTTON: 'TOGGLE_PASS_BUTTON',
} as const;

export const Input = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { error, testId, loading, datadogRumMask, autoComplete, ...omittedProps } = props;
  const [showPass, setShowPass] = useState<boolean>(false);
  const toggleShowPass = () => setShowPass(!showPass);

  const typeOfInput = props.type !== 'password' ? props.type : showPass ? 'text' : 'password';

  return (
    <div css={styles.container({ width: props.width })}>
      {props.unit && <div css={styles.unit}>{props.unit}</div>}
      <input
        {...omittedProps}
        data-testid={testId}
        ref={ref}
        id={props.id}
        name={props.name}
        type={typeOfInput}
        placeholder={props.placeholder}
        className={datadogRumMask ? 'dd-privacy-mask' : ''}
        css={styles.input({
          readOnly: props.readOnly,
          error: error,
        })}
        autoComplete={autoComplete}
      />

      {props.type === 'password' && (
        <div
          css={styles.eyeContainer.container}
          onClick={toggleShowPass}
          data-testid={TESTID.TOGGLE_PASS_BUTTON}
        >
          <img
            css={styles.eyeContainer.eye}
            src={showPass ? EyeInvisibles : EyeVisibles}
            alt={showPass ? MESSAGE.ALT_DISPLAY_PASS : MESSAGE.ALT_HIDE_PASS}
          />
        </div>
      )}

      {loading && <div css={styles.loadingCircle} />}
    </div>
  );
});
